
import _ from "lodash";
import axios from 'axios'
import Table from "./components/table/table";
import {GRAPHQL} from "../../const/graphql.const";
import {tableConfig} from "./components/orderList/config/tableConfig";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import SocketManager from "./components/SocketManager/SocketManager";
import { useDispatch } from "react-redux";
import { useLocalStore } from "@dladio/hooks";
import { dispatchAction } from "@dladio/app-bridge";
import {showConfirmDialog} from "../../store/reducers/confirm-dialog";
import {useEffect, useState} from "react";
import {shopifyOrderSelection} from "../../services/table-selection.service";
import { ICommandBarItemProps } from "@fluentui/react";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "../../const/local-store.const";
import { DefaultCommandBar, TitleBar } from "@dladio/core-ui";

const OrderSyncB2B = () => {
    return (
        <div>
            <ConfirmDialog/>
            <Header/>
            <OrderList/>
            <SocketManager/>
        </div>
    )
}

const Header = () => {

    const dispatch = useDispatch()
    const { model: PSModel, setStore: setPageState } = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    const cmdBarConfig: ICommandBarItemProps[] = [
        {
            key: '1',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => {
                dispatchAction({
                    action: 'navigate',
                    payload: -1
                }, "https://helios.dlad.io/")
            },
        },
        {
            key: '2',
            text: 'Sync',
            iconProps: { iconName: 'BulkUpload' },
            onClick: () => {
                dispatch(
                    showConfirmDialog({
                        title: "Confirmation Required!",
                        subtitle: "Are you sure you want to continue with the actions",
                        onConfirm: () => {
                            setPageState(PAGE_STATES.LOADING, true)

                            axios.post("https://teadrop-epc.dlad.io/api/method/shopify_connector.api.b2b.enqueue", {
                                orderList: shopifyOrderSelection.getSelection()?.map((line: any) => line?.id?.split('/')[4])
                            }, { withCredentials: true })
                        }
                    })
                );
            },
        },
        {
            key: '3',
            text: 'View Synced Orders',
            iconProps: { iconName: 'PageLink' },
            onClick: () => {
                dispatchAction({
                    action: 'navigate',
                    payload: '/app/page/483f0d6703'
                }, "https://helios.dlad.io/")
            },
        }
    ]


    return (
        <>
            <TitleBar
                title="Shopify Order Synchronization"
                subTitle={'B2B'}
            />
            <DefaultCommandBar actions={cmdBarConfig as any} />
        </>

    )
}

const OrderList = () => {

    const [value, setValue] = useState([])
    const { model: PSModel } = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    useEffect(() => {
        init()
    }, [])

    const init = () => {
        axios.post("https://teadrop-epc.dlad.io/api/method/shopify_connector.api.b2b.exec_query", {
            "gql": GRAPHQL.GET_SHOPIFY_ORDERS
        }, { withCredentials: true }).then((data: any) => {
            console.log(data);

            setValue(_.get(data, 'data.message.orders.nodes'))
        })
    }


    const tableMarkUp = <Table config={tableConfig} model={_.isArray(value) ? value : []} />

    return (
        <>
            {tableMarkUp}
        </>
    )
}

export default OrderSyncB2B