import {io} from "socket.io-client";
import RouterConfigs from "./router/router.config";
import {BrowserRouter} from "react-router-dom";
import {CoreUIProvider} from "@dladio/core-ui";
import {AppBridgeProvider} from "@dladio/app-bridge";

export const socket = io('wss://core.teadrop.us')

function App() {
    return (
        <AppBridgeProvider apiKey="shopify-connector">
            <CoreUIProvider>
                <BrowserRouter>
                    <RouterConfigs/>
                </BrowserRouter>
            </CoreUIProvider>
        </AppBridgeProvider>
    );
}

export default App;
