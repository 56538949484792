import _ from 'lodash'
import { Box } from "@dladio/core-ui";
import moment from 'moment';
import { useAutoFit, useOffsetTop } from "@dladio/hooks";
import { multiSearch } from "@dladio/utils";
import { shopifyOrderSelection } from "../../../../services/table-selection.service";
import React, { useEffect, useState } from "react";
import {
    Text,
    IColumn,
    SearchBox,
    DetailsList,
    SelectionMode,
    DetailsHeader,
    IDetailsListStyles,
    IDetailsHeaderProps,
    DetailsListLayoutMode,
    mergeStyles
} from "@fluentui/react";

interface IProps {
    config: any,
    model: any
}

const Table = ({ config, model }: IProps) => {
    const [lines, setLines] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [ref, offsetTop] = useOffsetTop()

    // useEffect(() => {
    //     setTimeout(() => {
    //         setAutoFit()
    //     }, 400)
    // }, [])

    useEffect(() => {
        setLines(model)
    }, [model])

    useEffect(() => {
        generateColumns()
    }, [model, config])

    const quantityFocusHandler = () => {

    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);

        if (column.fieldName === 'amount') {

            const amount: any = _.get(model, 'totalPriceSet.presentmentMoney.amount');
            const currencyCode: any = _.get(model, 'totalPriceSet.presentmentMoney.currencyCode');

            return (
                <Text variant={'small'}>
                    {currencyCode + ' ' + amount}
                </Text>
            )

        } else if (column.fieldName === 'createdAt') {
            return (
                <Text variant={'small'}>
                    {moment(fieldContent).format('DD-MM-YYYY')}
                </Text>
            )

        } else if (column.fieldName === 'riskLevel') {
            return (
                <Text variant={'xSmall'}
                    className={`${ColorConf[fieldContent]} font-semibold text-white  px-2.5 py-0.5 rounded`}>
                    {fieldContent}
                </Text>
            )
        }

        return <Text variant={'small'}>{fieldContent}</Text>
    }

    console.log(ColorConf.LOW)
    const generateColumns = () => {

        function _onColumnClick(event: React.MouseEvent<HTMLElement>, column: IColumn) {
            let isSortedDescending = column.isSortedDescending;
            if (column.isSorted) {
                isSortedDescending = !isSortedDescending;
            }

            let sortedItems = _copyAndSort(model, column.fieldName!, isSortedDescending);

            let columnList: any = columns.map((col: any) => {
                col.isSorted = col.key === column.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }

                return col;
            })

            setLines(sortedItems)
            setColumns(columnList)
        }

        let columns: IColumn[] = config.header_fields.map((line: any, index: any) => ({
            key: index,
            name: line.label,
            fieldName: line.key,
            page: line.page,
            dataType: line?.type === 'divider' ? null : line.data_type,
            meta: line?.field_meta,
            isResizable: true,
            minWidth: line?.min_width,
            maxWidth: line?.max_width,
            isSortedDescending: false,
            isSorted: false,
            styles: { root: { backgroundColor: '#f0f0f0' } },
            onColumnClick: _onColumnClick
        })
        )
        setColumns(columns)
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }
    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && model.length > 0 ? multiSearch(model, text) : model
        setLines(filteredLines)
    }

    let className = "overflow-auto h-lg"
    let height = `calc(100vh - ${offsetTop + 35}px)`

    return (
        <Box>
            <Text className="ms-2 mt-1 capitalize text-lg">Orders ({lines?.length ?? 0})</Text>
            <div className={'flex flex-wrap'}>
                <SearchBox onChange={searchTextChangeHandler} className="w-[25%] m-2 rounded-md"
                    placeholder="Search" />
            </div>

            <div ref={ref} className={className}>
                <DetailsList
                    className={mergeStyles(_.set(gridStyles, ['root', 'selectors', '& [role=grid]', 'height'], height))}
                    items={lines}
                    columns={columns}
                    selection={shopifyOrderSelection}
                    compact={true}
                    styles={gridStyles}
                    onActiveItemChanged={quantityFocusHandler}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.multiple}
                    selectionPreservedOnEmptyClick={true}
                />
            </div>
        </Box>
    )
}
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

const ColorConf: any = {
    LOW: "bg-green-600",
    MEDIUM: "bg-orange-600",
    HIGH: "bg-red-600"
}

export default Table