import _ from "lodash";
import Table from "../table/table";
import Loading from "../../../../components/Loading/Loading";
import { GRAPHQL } from "../../../../const/graphql.const";
import { useDerive } from "@dladio/service";
import { METHOD_API } from "../../../../const/method.const";
import { tableConfig } from "./config/tableConfig";
import { useLocalStore } from "@dladio/hooks";
import { LOCAL_STORE_KEYS, PAGE_STATES } from "../../../../const/local-store.const";
import { useEffect, useState } from "react";
import axios from 'axios'

const OrderList = () => {

    const [value, setValue] = useState([])
    const { model: PSModel } = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    useEffect(() => {
        init()
    }, [])

    const init = () => {
        axios.post("https://teadrop-epc.dlad.io/api/method/shopify_connector.api.exec_query", {
            "gql": GRAPHQL.GET_SHOPIFY_ORDERS
        }, { withCredentials: true }).then((data: any) => {
            console.log(data);
            
            setValue(_.get(data, 'data.message.orders.nodes'))
        })
    }

    console.log(value);
    

    // const {value, isFetching}: any = useDerive('exec', {
    //     options: {
    //         method: METHOD_API.GET_SHOPIFY_ORDERS,
    //         body: {
    //             "gql": GRAPHQL.GET_SHOPIFY_ORDERS
    //         }
    //     }, path: 'message.orders.nodes'
    // })

    const tableMarkUp = <Table config={tableConfig} model={_.isArray(value) ? value : []} />

    return (
        <>
            {tableMarkUp}
        </>
    )
}

export default OrderList
