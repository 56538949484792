export enum SOCKET_EVENTS {
    CONNECTION_ERROR = 'connect_error',
    ORDER_SYNC_TO_SAP = 'order_sync_to_sap'
}

export enum SOCKET_RESPONSE_EVENT {
    CREATE = 'CREATE',
    PROCESS = 'PROCESS',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

export enum SOCKET_RESPONSE_STATUS {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    INFO = 'INFO'
}