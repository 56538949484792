export const tableConfig: any = {
    header_fields: [
        {
            label: 'Order No',
            key: "name",
            min_width: 80,
            max_width: 100
        },
        {
            label: 'Risk Level',
            key: "riskLevel",
            min_width: 80,
            max_width: 150
        },
        {
            label: 'Email',
            key: "email",
            min_width: 80,
            max_width: 300
        },
        {
            label: 'Amount',
            key: "amount",
            min_width: 80,
            max_width: 150
        },
        {
            label: 'Doc Date',
            key: "createdAt",
            min_width: 80,
            max_width: 150
        }
    ]
}

