import OrderSync from "../pages/orderSync/orderSync";
import { useRoutes } from "react-router-dom";
import OrderSyncHeliosClient from "../pages/orderSync/orderSyncHeliosClient";
import OrderSyncB2B from "../pages/orderSync/orderSyncB2B";

const RouterConfigs = () => {
    const element = useRoutes(
        [
            {
                path: "/order-sync-to-sap",
                element: <OrderSync />,
            },
            {
                path: "/order-sync-to-sap-helios-client",
                element: <OrderSyncHeliosClient />,
            },
            {
                path: "/order-sync-to-sap-b2b",
                element: <OrderSyncB2B />,
            },
        ]);

    return element;
};

export default RouterConfigs;
