
import SocketManager from "./components/SocketManager/SocketManager";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Header from "./components/header/headerHeliosClient";
import OrderList from "./components/orderList/orderListHeliosClient";

const OrderSyncHeliosClient = () => {
    return (
        <div>
            <ConfirmDialog/>
            <Header/>
            <OrderList/>
            <SocketManager/>
        </div>
    )
}

export default OrderSyncHeliosClient