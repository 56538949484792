import _ from "lodash";
import Table from "../table/table";
import Loading from "../../../../components/Loading/Loading";
import {GRAPHQL} from "../../../../const/graphql.const";
import {useDerive} from "@dladio/service";
import {METHOD_API} from "../../../../const/method.const";
import {tableConfig} from "./config/tableConfig";
import {useLocalStore} from "@dladio/hooks";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "../../../../const/local-store.const";

const OrderList = () => {

    const {model: PSModel} = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    const {value, isFetching}: any = useDerive('exec', {
        options: {
            method: METHOD_API.GET_SHOPIFY_ORDERS,
            body: {
                "gql": GRAPHQL.GET_SHOPIFY_ORDERS
            }
        }, path: 'message.orders.nodes'
    })

    const tableMarkUp = <Table config={tableConfig} model={_.isArray(value) ? value : []}/>

    return (
        <>
            {isFetching || isLoading ? <Loading/> : tableMarkUp}
        </>
    )
}

export default OrderList