import _ from "lodash";
import {METHOD_API} from "../../../../const/method.const";
import {syncOrders} from "../../../../actions/actions";
import {useDispatch} from "react-redux";
import {useLocalStore} from "@dladio/hooks";
import {dispatchAction} from "@dladio/app-bridge";
import {showConfirmDialog} from "../../../../store/reducers/confirm-dialog";
import {ICommandBarItemProps} from "@fluentui/react";
import {shopifyOrderSelection} from "../../../../services/table-selection.service";
import {DefaultCommandBar, TitleBar} from "@dladio/core-ui";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "../../../../const/local-store.const";
import {middleErrorToast, middleSuccessToast} from "../../../../services/toast.service";

const Header = () => {

    const dispatch = useDispatch()
    const {model: PSModel, setStore: setPageState} = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    const cmdBarConfig: ICommandBarItemProps[] = [
        {
            key: '1',
            text: 'Back',
            iconProps: {iconName: 'Back'},
            onClick: () => {
                dispatchAction({
                    action: 'navigate',
                    payload: -1
                }, "https://teadrop.dlad.io/")
            },
        },
        {
            key: '2',
            text: 'Sync',
            iconProps: {iconName: 'BulkUpload'},
            disabled: isLoading,
            onClick: () => {
                dispatch(
                    showConfirmDialog({
                        title: "Confirmation Required!",
                        subtitle: "Are you sure you want to continue with the actions",
                        onConfirm: () => {
                            setPageState(PAGE_STATES.LOADING, true)
                            dispatch(syncOrders(METHOD_API.SYNC_SHOPIFY_ORDERS, {
                                orderList: shopifyOrderSelection.getSelection()?.map((line: any) => line?.id?.split('/')[4])
                            }, {forceRefetch: true}) as any)
                                .then((data: any) => middleSuccessToast("Process Enqueued"))
                                .catch((e: any) => middleErrorToast("Cannot Process"))

                            // console.log(shopifyOrderSelection.getSelection()?.map((line: any) => line?.id?.split('/')[4]),'abc')
                        }
                    })
                );
            },
        },
        {
            key: '3',
            text: 'View Synced Orders',
            iconProps: {iconName: 'PageLink'},
            onClick: () => {
                dispatchAction({
                    action: 'navigate',
                    payload: '/app/page/483f0d6703'
                }, "https://teadrop.dlad.io/")
            },
        },
        {
            key: '4',
            text: 'Pick & Pack Manager',
            iconProps: {iconName: 'PageLink'},
            onClick: () => {
                dispatchAction({
                    action: 'navigate',
                    payload: '/app/page/73b85b488c'
                }, "https://teadrop.dlad.io/")
            },
        },
    ]


    return (
        <>
            <TitleBar
                title="Shopify Order Synchronization"
                subTitle={'Shopify'}
            />
            <DefaultCommandBar actions={cmdBarConfig as any}/>
        </>

    )
}
export default Header